/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { concatMap, from, Observable } from 'rxjs';

import * as dayjs from 'dayjs';

import { SecurityHelper } from '../../helpers/security-helper';
import { EncryptionService } from '@tung-ngern/common/fetches';

@Injectable({
  providedIn: 'root',
})
export class EventInterceptorService implements HttpInterceptor {
  constructor(private encryptionService: EncryptionService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let cryptoKey: CryptoKey;

    const index = req.url.indexOf('/assets/');
    const googleapis = req.url.indexOf('https://storage.googleapis.com');
    if (index !== -1 || googleapis !== -1) {
      return next.handle(req);
    }

    const uuid = SecurityHelper.UUID();
    const cipher = SecurityHelper.RandomString(16);

    const bodyData = {
      ...req.body,
      headerReq: {
        ...req.body.headerReq,
        reqID: uuid,
        reqDtm: dayjs().format('YYYY-MM-DD HH:mm:ss:SSS'),
      },
    };

    return from(this.encryptionService.encrypt(bodyData)).pipe(
      concatMap(({ param1, param2, key }) => {
        cryptoKey = key;
        const request: HttpRequest<any> = req.clone({
          body: { param1, param2 },
        });
        return next.handle(request).pipe(
          concatMap(async (res) => {
            if (res instanceof HttpResponse) {
              const decodeData = await this.encryptionService.decrypt(
                res.body,
                cryptoKey
              );
              res = res.clone({
                body: decodeData,
              });
            }
            return res;
          })
        );
      })
    );
  }
}
