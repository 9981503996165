import { APP_INITIALIZER, FactoryProvider, Injectable } from '@angular/core';
import { LatLngService, MasterDataService } from '@tung-ngern/common/fetches';
import { SearchFilterService } from '@tung-ngern/ui';

import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { ConsoleToggleService } from './services/console-toggle.service';

export function initialize(
  initializeService: InitializeService
): () => Observable<void> {
  return () => initializeService.initialize();
}

@Injectable({ providedIn: 'root' })
export class InitializeService {
  constructor(
    private masterDataService: MasterDataService,
    private latLngService: LatLngService,
    private consoleToggleService: ConsoleToggleService,
    private searchFilterService: SearchFilterService
  ) {}
  public initialize(): Observable<void> {
    this.consoleToggleService.disableConsoleInProduction();
    return forkJoin([
      this.masterDataService.getProvinceList(),
      this.masterDataService.fetchMasterData(),
      this.latLngService.getGeoloLocation(),
      this.masterDataService.getKioskList(),
    ]).pipe(
      switchMap(() => {
        console.log('App start.');
        this.searchFilterService.init();
        return of();
      })
    );
  }
}

export const InitializeProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initialize,
  deps: [InitializeService],
  multi: true,
};
